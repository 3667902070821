import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/_guards/auth.guard';
import { AdminComponent } from './admin.component';

const routes: Routes = [
    {
        'path': '',
        'component': AdminComponent,
        'canActivate': [AuthGuard],
        'children': [
            {
                'path': 'admin',
                'loadChildren': '.\/pages\/dashboard\/dashboard.module#DashboardModule',
            },
            {
                'path': 'convidado',
                'loadChildren': '.\/pages\/convidado\/convidado.module#ConvidadoModule',
            },
            {
                'path': 'cupons',
                'loadChildren': '.\/pages\/cupons\/cupons.module#CuponsModule',
            },
            {
                'path': 'perfil',
                'loadChildren': '.\/pages\/perfil\/perfil.module#PerfilModule',
            },
            {
                'path': 'pedidos',
                'loadChildren': '.\/pages\/pedidos\/pedidos.module#PedidosModule',
            },
            {
                'path': 'vendas',
                'loadChildren': '.\/pages\/resgates-LI\/resgates-LI.module#ResgatesLIModule',
            },
            {
                'path': 'franquia',
                'loadChildren': '.\/pages\/franquia\/franquia.module#FranquiaModule',
            },
            {
                'path': 'addEvento',
                'loadChildren': '.\/pages\/addEvento\/addEvento.module#AddEventoModule',
            },
            {
                'path': 'admin-eventos',
                'loadChildren': '.\/pages\/eventos\/eventos.module#EventosModule',
            },
            {
                'path': 'profile-eventos',
                'loadChildren': '.\/pages\/profile-eventos\/profile-eventos.module#ProfileEventosModule',
            },
            {
                'path': 'parceiros',
                'loadChildren': '.\/pages\/parceiros\/parceiros.module#ParceirosModule',
            },
            {
                'path': 'inspiracao',
                'loadChildren': '.\/pages\/inspiracao\/inspiracao.module#InspiracaoModule',
            },
            {
                'path': 'fornecedores',
                'loadChildren': '.\/pages\/fornecedores\/fornecedores.module#FornecedoresModule',
            },
            {
                'path': 'produto',
                'loadChildren': '.\/pages\/produto\/produto.module#ProdutoModule',
            },
            {
                'path': 'portfolio',
                'loadChildren': '.\/pages\/portfolio\/portfolio.module#PortfolioModule',
            },
            {
                'path': 'editar-portfolio',
                'loadChildren': '.\/pages\/editarPortfolio\/editar_portfolio.module#EditarPortfolioModule',
            },
            {
                'path': 'editar-inspiracao',
                'loadChildren': '.\/pages\/editarInspiracao\/editar_inspiracao.module#EditarInspiracaoModule',
            },
            {
                'path': 'relatorio-evento',
                'loadChildren': '.\/pages\/relatorio-evento\/relatorio-evento.module#RelatorioEventoModule',
            },
            {
                'path': 'voucher',
                'loadChildren': '.\/pages\/voucher\/voucher.module#VoucherModule',
            },
            {
                'path': 'servico-gift',
                'loadChildren': '.\/pages\/servico-gift\/servico-gift.module#ServicoGiftModule',
            },
            {
                'path': '',
                'redirectTo': 'admin',
                'pathMatch': 'full',
            },
        ],
    },
    {
        'path': '**',
        'redirectTo': 'admin',
        'pathMatch': 'full',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminRoutingModule { }