import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class EstabelecimentoService {

    private URL = `${environment.api}`;
    userToken: any = localStorage.getItem('pipoohUser');

    constructor(private http: HttpClient, private toastr: ToastrService) { }

    authFormData(formData) {
        this.userToken = localStorage.getItem('pipoohUser');
        formData.token = this.userToken;
        return formData;
    }
    postData(url, formData): Promise<any> {
        return this.http.post(url, formData).toPromise().then(response => {
            var aux: any = response;
            if (aux.success == false) {
                this.toastr.error(aux.error, "Erro de solicitação");
                return aux;
            }
            else {
                return aux;
            }
        });
    }

    updateSenha(formData) {
        return this.postData(`${this.URL}estabelecimento/update/senha`, this.authFormData(formData));
    }
    updateSenhaEquipe(formData) {
        return this.postData(`${this.URL}estabelecimento/update/senha/equipe`, this.authFormData(formData));
    }
    updateDados(formData) {
        return this.postData(`${this.URL}estabelecimento/update/dados`, this.authFormData(formData));
    }
    updateDadosBancarios(formData) {
        return this.postData(`${this.URL}estabelecimento/update/dadosBancarios`, this.authFormData(formData));
    }
    updateCashback(formData) {
        return this.postData(`${this.URL}estabelecimento/update/cashback`, this.authFormData(formData));
    }
    updateDescricao(formData) {
        return this.postData(`${this.URL}estabelecimento/update/descricao`, this.authFormData(formData));
    }
    updateCallback(formData) {
        return this.postData(`${this.URL}estabelecimento/update/callback`, this.authFormData(formData));
    }
    buscarSaldo(formData) {
        return this.postData(`${this.URL}estabelecimento/select/saldo`, this.authFormData(formData));
    }
    preCadastro(formData) {
        return this.postData(`${this.URL}estabelecimento/insert`, this.authFormData(formData));
    }
    buscarCategorias(formData) {
        return this.postData(`${this.URL}categoriaEstabelecimento/select/lista`, this.authFormData(formData));
    }
    updateToken(formData) {
        return this.postData(`${this.URL}estabelecimento/update/token/api`, this.authFormData(formData));
    }
    selectEstabelecimentos(formData) {
        return this.postData(`${this.URL}estabelecimento/estabelecimento/select/lista`, this.authFormData(formData));
    }
    selectEstabelecimentosFornecedor(formData) {
        return this.postData(`${this.URL}estabelecimento/estabelecimento/select/fornecedor`, this.authFormData(formData));
    }

    selecionarCategoria(formData) {
        return this.postData(`${this.URL}categoriaEstabelecimento/select/lista`, this.authFormData(formData));
    }
    inserirReacao(formData) {
        return this.postData(`${this.URL}estabelecimento/estabelecimento/insert/reacao`, this.authFormData(formData));
    }
    selectReacaoFornecedor(formData) {
        return this.postData(`${this.URL}estabelecimento/estabelecimento/select/reacao/fornecedor`, this.authFormData(formData));
    }
    insertUsuario(formData) {
        return this.postData(`${this.URL}estabelecimento/estabelecimento/insert/usuario`, this.authFormData(formData));
    }
    selectUsuario(formData) {
        return this.postData(`${this.URL}estabelecimento/estabelecimento/select/usuario`, this.authFormData(formData));
    }

    updateDisparoExterno(formData) {
        return this.postData(`${this.URL}estabelecimento/estabelecimento/update/disparo/externo`, this.authFormData(formData));
    }

    updateCabecalhoDisparo(formData) {
        return this.postData(`${this.URL}estabelecimento/estabelecimento/update/cabecalho/disparo`, this.authFormData(formData));
    }

}